<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="loading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Vehicle Running
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          @click="newItem()"
          v-if="
            $_checkPermission(
              this.auth.permissions,
              'Manage Vehicle Running Logs',
              'Create'
            )
          "
        >
          <v-icon left> mdi-plus </v-icon> New Log
        </v-btn>
      </v-card-title>
      <v-card-title class="justify-center justify-sm-start">
        <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="changeView"
            background-color="transparent"
          >
            <v-tab :tab-value="1">My Logs</v-tab>
            <v-divider vertical></v-divider>
            <v-tab
              :tab-value="2"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Running Logs',
                  'Read'
                )
              "
              >All Logs</v-tab
            >
          </v-tabs>
        </v-card>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-text-field
          filled
          dense
          outlined
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        :search="search"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.edit_status == 'DISABLED'"
            depressed
            small
            color="primary"
            class="mx-0 px-0 mt-1"
            dark
            @click="viewItem(item)"
          >
            more
          </v-btn>
          <v-badge
            bordered
            :color="
              item.edit_status == 'REQUEST_WAITING'
                ? 'orange'
                : item.edit_status == 'REQUEST_REJECTED'
                ? 'error'
                : 'green'
            "
            dot
            overlap
            v-else
          >
            <v-btn
              depressed
              small
              color="primary"
              class="mx-0 px-0 mt-1"
              dark
              @click="viewItem(item)"
            >
              more
            </v-btn>
          </v-badge>
        </template>
        <template v-slot:item.description="{ item }">
          <v-sheet class="text-truncate" max-width="200" color="transparent">
            {{ item.description }}
          </v-sheet>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-sheet
          class="d-flex align-center text-caption px-2"
          color="transparent"
        >
          <v-badge dot color="orange" class="me-3"></v-badge> Edit Access
          Requested
        </v-sheet>
        <v-sheet
          class="d-flex align-center text-caption px-2"
          color="transparent"
        >
          <v-badge dot color="red" class="me-3"></v-badge> Edit Access Rejected
        </v-sheet>
        <v-sheet
          class="d-flex align-center text-caption px-2"
          color="transparent"
        >
          <v-badge dot color="green" class="me-3"></v-badge> Edit Access Granted
        </v-sheet>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { fetchAndStoreMyVehicleRunning } from "@/services/myDataService";
import { syncOfflineVehicleRunningChanges } from "@/services/vehicleRunningService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    //
  },
  data: () => ({
    tab: 1,
    loading: false,
    search: "",
    headers: [
      {
        text: "Log Id",
        value: "id",
      },
      {
        text: "Date",
        value: "date",
      },
      { text: "Vehicle", value: "vehicles.vehicle_no" },
      { text: "Project", value: "projects.code" },
      { text: "Total (km)", value: "total_km" },
      { text: "Action", value: "actions", sortable: false },
    ],
    items: [],
  }),
  async created() {
    this.loading = true;
    await syncOfflineVehicleRunningChanges();
    this.items = await fetchAndStoreMyVehicleRunning();
    this.loading = false;
  },
  mounted() {
    //
  },
  methods: {
    viewItem(item) {
      this.$router.push({
        name: "PageViewVehicleRunning",
        params: { uuid: item.uuid },
        query: { bc: item.id, mylog: "true" },
      });
    },
    newItem() {
      this.$router.push({ name: "PageNewVehicleRunning" });
    },
    changeView() {
      if (this.tab == 2) {
        this.$router.push({
          name: "PageAllVehicleRunningList",
        });
      }
    },
  },
};
</script>